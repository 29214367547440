import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'vsf-layout-footer',
    templateUrl: './layout-footer.component.html',
    styleUrls: ['./layout-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFooterComponent {
}
