import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, combineLatest, merge, Observable, of } from "rxjs";
import {
    distinctUntilChanged,
    map,
    mapTo,
    scan,
    share,
    shareReplay,
    skip,
    switchMap,
    take,
    tap,
} from "rxjs/operators";

import {
    FacetValueFilterInput,
    GetCollectionQuery,
    GetCollectionQueryVariables,
    SearchProductsQuery,
    SearchProductsQueryVariables,
    SortOrder
} from "../../../common/generated-types";
import { getRouteArrayParam } from "../../../common/utils/get-route-array-param";
import { AssetPreviewPipe } from "../../../shared/pipes/asset-preview.pipe";
import { DataService } from "../../providers/data/data.service";
import { StateService } from "../../providers/state/state.service";

import { GET_COLLECTION, SEARCH_PRODUCTS } from "./product-list.graphql";
import {FacetsFilterHelperService} from "../../services/facets-filter-helper.service";

type SearchItem = SearchProductsQuery["search"]["items"][number];

@Component({
    selector: "vsf-product-list",
    templateUrl: "./product-list.component.html",
    styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent implements OnInit {
    searchResultError = '';
    selectedFilter: string;
    products$: Observable<SearchItem[]>;
    totalResults$: Observable<number>;
    collection$: Observable<GetCollectionQuery["collection"]>;
    facetValues: SearchProductsQuery["search"]["facetValues"];
    unfilteredTotalItems = 0;
    activeFacetValueIds$: Observable<string[]>;
    searchTerm$: Observable<string>;
    displayLoadMore$: Observable<boolean>;
    loading$: Observable<boolean>;
    breadcrumbs$: Observable<Array<{ id: string; name: string }>>;
    mastheadBackground$: Observable<SafeStyle>;
    private currentPage = 0;
    private refresh = new BehaviorSubject<void>(undefined);
    readonly placeholderProducts = Array.from({ length: 12 }).map(() => null);
    selectedFilters: {facetType: string, selectedIds: string[]}[] = [];

    constructor(
        private dataService: DataService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private sanitizer: DomSanitizer,
        private facetsFilterHelperService: FacetsFilterHelperService
    ) {}

    ngOnInit() {
        const perPage = 24;
        const collectionSlug$ = this.route.paramMap.pipe(
            map((pm) => pm.get("slug")),
            distinctUntilChanged(),
            tap((slug) => {
                this.stateService.setState("lastCollectionSlug", slug || null);
                this.currentPage = 0;
            }),
            shareReplay(1)
        );
        this.activeFacetValueIds$ = this.route.paramMap.pipe(
            map((pm) => getRouteArrayParam(pm, "facets")),
            distinctUntilChanged((x, y) => x.toString() === y.toString()),
            tap(() => {
                this.currentPage = 0;
            }),
            shareReplay(1)
        );
        this.searchTerm$ = this.route.queryParamMap.pipe(
            map((pm) => pm.get("search") || ""),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.collection$ = collectionSlug$.pipe(
            switchMap((slug) => {
                if (slug) {
                    return this.dataService
                        .query<GetCollectionQuery, GetCollectionQueryVariables>(
                            GET_COLLECTION,
                            {
                                slug,
                            }
                        )
                        .pipe(map((data) => data.collection));
                } else {
                    return of(undefined);
                }
            }),
            shareReplay(1)
        );

        const assetPreviewPipe = new AssetPreviewPipe();

        this.mastheadBackground$ = this.collection$.pipe(
            map(
                (c) =>
                    "url(" +
                    assetPreviewPipe.transform(
                        c?.featuredAsset || undefined,
                        1000,
                        300
                    ) +
                    ")"
            ),
            map((style) => this.sanitizer.bypassSecurityTrustStyle(style))
        );

        this.breadcrumbs$ = this.collection$.pipe(
            map((collection) => {
                if (collection) {
                    return collection.breadcrumbs;
                } else {
                    return [
                        {
                            id: "",
                            name: "Home",
                        },
                        {
                            id: "",
                            name: "Search",
                        },
                    ];
                }
            })
        );

        const triggerFetch$ = combineLatest(
            this.collection$,
            this.activeFacetValueIds$,
            this.searchTerm$,
            this.refresh
        );
        const getInitialFacetValueIds = () => {
            combineLatest(this.collection$, this.searchTerm$)
                .pipe(
                    take(1),
                    switchMap(([collection, term]) => {
                        return this.dataService.query<
                            SearchProductsQuery,
                            SearchProductsQueryVariables
                        >(SEARCH_PRODUCTS, {
                            input: {
                                term,
                                groupByProduct: true,
                                collectionId: collection?.id,
                                take: perPage*2,
                                skip: this.currentPage * perPage,
                                inStock: true,
                                sort: {
                                    name: SortOrder.ASC,
                                    price: SortOrder.ASC,
                                }
                            },
                        });
                    })
                )
                .subscribe((data) => {
                    this.facetValues = data.search.facetValues;
                    this.unfilteredTotalItems = data.search.totalItems;
                });
        };
        this.loading$ = merge(triggerFetch$.pipe(mapTo(true)));
        const queryResult$ = triggerFetch$.pipe(
            switchMap(([collection, facetValueIds, term]) => {
                return this.dataService
                    .query<SearchProductsQuery, SearchProductsQueryVariables>(
                        SEARCH_PRODUCTS,
                        {
                            input: {
                                term,
                                groupByProduct: true,
                                collectionId: collection?.id,
                                facetValueFilters: this.mapSelectedFiltersToAndOr(this.selectedFilters, facetValueIds),
                                take: perPage*2,
                                skip: this.currentPage * perPage,
                                inStock: true,
                                sort: {
                                    name: SortOrder.ASC,
                                    price: SortOrder.ASC,
                                }
                            },
                        }
                    )
                    .pipe(
                        tap((data) => {
                            if (facetValueIds.length === 0) {
                                this.facetValues = data.search.facetValues;
                                this.unfilteredTotalItems =
                                    data.search.totalItems;
                            } else if (!this.facetValues) {
                                getInitialFacetValueIds();
                            } else {
                                this.facetValues = this.facetValues.map(
                                    (fv) => fv
                                );
                            }
                            if (data.search.totalItems === 0) {
                                this.searchResultError = 'Nie znaleziono żadnych pasujących ofert';
                            } else {
                                this.searchResultError = '';
                            }
                        })
                    );
            }),
            shareReplay(1)
        );

        this.loading$ = merge(
            triggerFetch$.pipe(mapTo(true)),
            queryResult$.pipe(mapTo(false))
        );

        const RESET = "RESET";
        const items$ = (this.products$ = queryResult$.pipe(
            map((data) => data.search.items)
        ));
        const reset$ = merge(
            collectionSlug$,
            this.activeFacetValueIds$,
            this.searchTerm$
        ).pipe(mapTo(RESET), skip(1), share());
        this.products$ = merge(items$, reset$).pipe(
            scan<SearchItem[] | string, SearchItem[]>((acc, val) => {
                if (typeof val === "string") {
                    return [];
                } else {
                    return acc.concat(val);
                }
            }, [] as SearchItem[])
        );
        this.totalResults$ = queryResult$.pipe(
            map((data) => data.search.totalItems)
        );
        this.displayLoadMore$ = combineLatest(
            this.products$,
            this.totalResults$
        ).pipe(
            map(([products, totalResults]) => {
                return 0 < products.length && products.length < totalResults;
            })
        );
    }

    trackByProductId(index: number, item: SearchItem) {
        return item.productId;
    }

    loadMore() {
        this.currentPage++;
        this.refresh.next();
    }

    onChangeFilters($event: { facetType: string; selectedIds: string[] }[]) {
        this.selectedFilters = $event;
        this.facetsFilterHelperService.selectedFilters.next(this.selectedFilters);
    }

    mapSelectedFiltersToAndOr(selectedFilters: {facetType: string, selectedIds: string[]}[], facetValueIds: string[]):  FacetValueFilterInput[]
    {

        let facetValueFilterInput: FacetValueFilterInput[] = [];
        if (facetValueIds?.length === 1) {
            facetValueFilterInput.push({and: facetValueIds[0]});
            return facetValueFilterInput;
        }
        selectedFilters.forEach(x => {
            if(x.selectedIds?.length === 1) {
                facetValueFilterInput.push({and: x.selectedIds[0]})
            }
            if (x.selectedIds?.length > 1) {
                facetValueFilterInput.push({ or: [...x.selectedIds] })
            }
        })
        return facetValueFilterInput;
    }
}
