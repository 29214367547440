import { gql } from "apollo-angular";

import {
    ASSET_FRAGMENT,
    CART_FRAGMENT,
    ERROR_RESULT_FRAGMENT,
} from "../../../common/graphql/fragments.graphql";

export const GET_PRODUCT_DETAIL = gql`
    query GetProductDetail($slug: String!) {
        product(slug: $slug) {
            id
            name
            description
            customFields {
                ProductCustomField1
                ProductCustomField2
                ProductCustomField3
                ProductCustomField4
                ProductCustomField5
            }
            variants {
                customFields {
                    ProductVariantCustomField1
                    ProductVariantCustomField2
                    ProductVariantCustomField3
                    ProductVariantCustomField4
                    ProductVariantCustomField5
                }
                id
                name
                options {
                    code
                    name
                }
                price
                priceWithTax
                sku
                variantFeaturedAsset: featuredAsset {
                    ...Asset
                    __typename
                }
                variantAssets: assets {
                    ...Asset
                    __typename
                }
            }
            featuredAsset {
                ...Asset
            }
            assets {
                ...Asset
            }
            collections {
                id
                slug
                breadcrumbs {
                    id
                    name
                    slug
                }
            }
        }
    }
    ${ASSET_FRAGMENT}
`;

export const ADD_TO_CART = gql`
    mutation AddToCart($variantId: ID!, $qty: Int!) {
        addItemToOrder(productVariantId: $variantId, quantity: $qty) {
            ...Cart
            ...ErrorResult
            ... on InsufficientStockError {
                order {
                    ...Cart
                }
            }
        }
    }
    ${CART_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const GET_PRODUCT_WITH_FACETS = gql`
    query GetProductDetail($id: ID!) {
        product(id: $id) {
            id
            facetValues {
                id
                name
                code
                facet {
                    name
                    id
                }
            }
        }
    }
`;

export const GET_VARIANTS = gql`
    query GETVARIANTS($slug: String!) {
        product(slug: $slug) {
            id
            variants {
                id
                assets {
                    preview
                }
                price
                priceWithTax
                facetValues {
                    id
                    name
                    facet {
                        name
                        id
                    }
                }
            }
        }
    }
`;
