import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class CartContentsService {
    constructor(private http: HttpClient) {}

    private getOrderShippingMethodsUrl = environment.postgrestApiUrl + "/get_order_shipping_methods";
    private startTransactionUrl = environment.postgrestApiUrl + "/start_transaction";


    private selectedOptions: BehaviorSubject<any[]|null> = new BehaviorSubject<any[]|null>(null);
    selectedOptions$ = this.selectedOptions.asObservable();

    private goToPaymentButtonClick: Subject<boolean> = new Subject<boolean>();
    goToPaymentButtonClick$ = this.goToPaymentButtonClick.asObservable();

    private paymentMethodId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    paymentMethodId$ = this.paymentMethodId.asObservable();

    setSelectedOptions(data: any) {
        this.selectedOptions.next(data);
    }

    emitPaymentButtonClick(clicked: boolean) {
        this.goToPaymentButtonClick.next(clicked);
    }

    setPaymentMethodId(id: number) {
        this.paymentMethodId.next(id);
    }
    handleAuthError(error: any): Observable<any> {
        if (error && error.error && error.error.message) {
            console.error(error.error.message);
        } else if (error && error.message) {
            console.error(error.message);
        } else {
            console.error(JSON.stringify(error));
        }
        return throwError(error);
    }

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            'Prefer': "params=single-object",
        }),
    };

    getOrderShippingMethods(body: any): Observable<any> {
        return this.http
            .post(this.getOrderShippingMethodsUrl, body, this.httpOptions)
            .pipe(catchError((err) => this.handleAuthError(err)));
    }

    startTransaction(body: any): Observable<any> {
        return this.http
            .post(this.startTransactionUrl, body, this.httpOptions)
            .pipe(catchError((err) => this.handleAuthError(err)));
    }
}
