<section class="flex justify-center items-center m-auto h-screen max-w-3xl">
  <div class="absolute inset-0 overflow-hidden">
    <img
      class="inset-0 w-full object-cover h-full"
      src="../../../../assets/banner.jpg"
      alt="header"
      loading="lazy"
    />
  </div>
  <div aria-hidden="true" class="absolute inset-0 bg-gray-900 opacity-80"></div>

  <div
    class="relative text-center flex flex-col items-center text-2xl text-white gap-4 flex items-center justify-center flex-col"
  >
    <div
      class="flex text-6xl text-transparent bg-clip-text font-extrabold tracking-normal lg:text-6xl bg-gradient-to-r from-white to-gray-200"
    >
      <span class="text-[#aa2d2f] glow">Płytowisko</span>
    </div>
    <span class="homepage__text">
      Internetowa giełda płytowa (marketplace), gdzie możesz wygodnie kupować i
      sprzedawać swoje ulubione nagrania
    </span>
    <input
      [formControl]="searchTerm"
      (keydown.enter)="doSearch(searchTerm.value)"
      type="text"
      placeholder="Wyszukaj oferty..."
      aria-label="Search input"
      class="homepage__field text-gray-500 shadow-sm focus:ring-primary-500 p-3 focus:border-primary-500 w-full text-sm border-gray-300 rounded-md"
    />
    <span class="text-base homepage__text">lub</span>
    <a class="text-base header__link" [routerLink]="['category/vinyls']"
      >przeglądaj wszystkie oferty</a
    >
  </div>
</section>
