<div *ngIf="activeCustomer$ | async as customer; else notLoggedIn">
    <a class="btn btn-light text-white" [routerLink]="['/account']">
        <fa-icon icon="user-circle" class="user"></fa-icon>
        <span class="d-none d-sm-inline ml-1 user">{{ userName(customer) }}</span>
    </a>
</div>
<ng-template #notLoggedIn>
    <a class="btn btn-light text-white pl-14 flex items-center gap-1" [routerLink]="['account/sign-in']">
        <fa-icon icon="user-circle" class="user fa-lg"></fa-icon>
        <span class="d-none d-sm-inline ml-1 login__text">Zaloguj się</span></a>
</ng-template>
