import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchProductsQuery } from "../../../common/generated-types";
import {
    faCaretDown,
    faChevronDown,
    faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { UntypedFormControl } from "@angular/forms";
import { FacetsFilterHelperService } from "../../services/facets-filter-helper.service";

export interface FacetWithValues {
    id: string;
    name: string;
    values: Array<{
        id: string;
        name: string;
        count: number;
    }>;
}

export interface ProductWithFacets {
    id: string;
    facetName: string;
}

export class PriceFilter {
    isPriceFilterActive: boolean;
    priceRange: PriceRange;
}

export class PriceRange {
    priceFrom?: number;
    priceTo?: number;
}

@Component({
    selector: "vsf-product-list-controls",
    templateUrl: "./product-list-controls.component.html",
    styleUrls: ["./product-list-controls.component.scss"],
})
export class ProductListControlsComponent implements OnChanges, OnDestroy {
    @Input() activeFacetValueIds: string[] = [];
    @Input() facetValues: SearchProductsQuery["search"]["facetValues"] | null;
    @Input() totalResults = 0;
    @Output() selectedFiltersEventEmitter = new EventEmitter<
        { facetType: string; selectedIds: string[] }[]
    >();

    selectedPriceRange: string;
    selected = "all";
    showFilters: { [key: string]: boolean } = {};
    selectedFacetValueIds: string[] = [];
    selectedFilters: { facetType: string; selectedIds: string[] }[] = [];
    priceFilter: PriceFilter = {
        isPriceFilterActive: false,
        priceRange: { priceFrom: undefined, priceTo: undefined },
    };

    filters: MapFacetNames[] = [
        {
            name: "Title",
            displayName: "Tytuł",
        },
        { name: "Artist", displayName: "Artysta" },
        { name: "Label", displayName: "Wytwórnia" },
        { name: "Catalogue Number", displayName: "Numer katalogowy" },
        { name: "Genre", displayName: "Gatunek" },
        { name: "Style", displayName: "Styl" },
        { name: "Format", displayName: "Format" },
        { name: "Country", displayName: "Kraj wydania" },
        { name: "Released", displayName: "Rok wydania" },
        { name: "Discogs Release", displayName: "Discogs release" },
        {
            name: "Discogs Master Release",
            displayName: "Discogs master release",
        },
        { name: "Identifier", displayName: "Dodatkowe oznaczenia" },
        { name: "Media condition", displayName: "Stan płyty" },
        { name: "Sleeve condition", displayName: "Stan okładki" },
        { name: "Seller", displayName: "Sprzedawca" },
    ];

    @Input() autoSearch = false;
    searchTerm = new UntypedFormControl("");
    facets: FacetWithValues[];
    manuallyExpanded = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        library: FaIconLibrary,
        private facetsFilterHelperService: FacetsFilterHelperService
    ) {
        library.addIcons(faChevronDown, faChevronUp, faCaretDown);
    }

    toggleFilters(facetId: string) {
        this.showFilters[facetId] = !this.showFilters[facetId];
    }

    isListOpen(facetId: string): boolean {
        return this.showFilters[facetId] || false;
    }

    get filtersExpanded(): boolean {
        return this.manuallyExpanded || this.activeFacetValueIds.length > 0;
    }

    ngOnInit() {}

    ngOnDestroy(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if ("facetValues" in changes) {
            this.facets = this.groupFacetValues(this.facetValues);
        }
    }

    isActive(facetValueId: string): boolean {
        return this.activeFacetValueIds.includes(facetValueId);
    }

    toggleFacetValueIdInRoute(id: string) {
        this.router.navigate(
            [
                "./",
                {
                    facets: this.toggleFacetValueId(id),
                },
            ],
            {
                queryParamsHandling: "merge",
                relativeTo: this.route,
                state: {
                    noScroll: true,
                },
            }
        );
    }

    toggleFacetValueId(id: string): string[] {
        const existing = this.activeFacetValueIds;
        return existing.includes(id)
            ? existing.filter((x) => x !== id)
            : existing.concat(id);
    }

    trackById(index: number, item: { id: string }) {
        return item.id;
    }

    private groupFacetValues(
        facetValues: SearchProductsQuery["search"]["facetValues"] | null
    ): FacetWithValues[] {
        if (!facetValues) {
            return [];
        }

        const activeFacetValueIds = this.activeFacetValueIds;

        const facetMap = new Map<string, FacetWithValues>();
        for (const {
            count,
            facetValue: { id, name, facet },
        } of facetValues) {
            if (
                count === this.totalResults &&
                !activeFacetValueIds.includes(id)
            ) {
                // skip FacetValues that do not have any effect on the
                // result set and are not active
                continue; // po usunięciu continue wyświetli się wszystko z jsona
            }

            const facetFromMap = facetMap.get(facet.id);
            if (facetFromMap) {
                facetFromMap.values.push({ id, name, count });
            } else {
                facetMap.set(facet.id, {
                    id: facet.id,
                    name: facet.name,
                    values: [{ id, name, count }],
                });
            }
        }
        return Array.from(facetMap.values());
    }

    changeRoute(ids: string[]): void {
        this.router.navigate(
            [
                "./",
                {
                    facets: [...ids],
                },
            ],
            {
                queryParamsHandling: "merge",
                relativeTo: this.route,
                state: {
                    noScroll: true,
                },
            }
        );
    }

    onAddedFacetValueId(selectedFacetValue: { facetType: string; id: string }) {
        this.selectedFacetValueIds.push(selectedFacetValue.id);
        this.addToSelectedFilters(selectedFacetValue);
        this.selectedFiltersEventEmitter.emit(this.selectedFilters);
        this.changeRoute(this.selectedFacetValueIds);
    }

    onDeleteFacetValueId(deletedFaceValue: { facetType: string; id: string }) {
        const indexOfDeleteFacetValue = this.selectedFacetValueIds.indexOf(
            deletedFaceValue.id
        );
        this.selectedFacetValueIds.splice(indexOfDeleteFacetValue, 1);
        this.deleteFromSelectedFilters(deletedFaceValue);
        this.selectedFiltersEventEmitter.emit(this.selectedFilters);
        this.changeRoute(this.selectedFacetValueIds);
    }

    onClickedFacetLink(clickedFacetValue: { facetType: string; id: string }) {
        this.selectedFacetValueIds = [];
        this.selectedFacetValueIds.push(clickedFacetValue.id);
        this.selectedFilters = [];
        this.addToSelectedFilters(clickedFacetValue);
        this.selectedFiltersEventEmitter.emit(this.selectedFilters);
    }

    getFacetsValuesFromFacetTypeName(name: string) {
        return this.facets.find((x) => x.name === name)?.values;
    }

    addToSelectedFilters(selectedFacetValue: {
        facetType: string;
        id: string;
    }) {
        const foundIndex = this.selectedFilters.findIndex(
            (x) => x.facetType === selectedFacetValue.facetType
        );

        if (foundIndex > -1) {
            this.selectedFilters[foundIndex].selectedIds.push(
                selectedFacetValue.id
            );
        } else {
            this.selectedFilters.push({
                facetType: selectedFacetValue.facetType,
                selectedIds: [selectedFacetValue.id],
            });
        }
    }

    deleteFromSelectedFilters(deletedFaceValue: {
        facetType: string;
        id: string;
    }) {
        const foundIndex = this.selectedFilters.findIndex(
            (x) => x.facetType === deletedFaceValue.facetType
        );

        if (foundIndex > -1) {
            if (this.selectedFilters[foundIndex].selectedIds.length === 1) {
                this.selectedFilters[foundIndex].selectedIds = [];
            } else {
                this.selectedFilters[foundIndex].selectedIds.splice(
                    foundIndex,
                    1
                );
            }
        }
    }

    onPrizeFromChange(priceFrom: string) {
        if (priceFrom === "") {
            this.priceFilter.priceRange.priceFrom = undefined;
        } else {
            this.priceFilter.priceRange.priceFrom = parseInt(priceFrom);
            if (this.priceFilter.priceRange.priceTo !== undefined) {
                if (
                    this.priceFilter.priceRange.priceFrom >
                    this.priceFilter.priceRange.priceTo
                ) {
                    this.priceFilter.priceRange.priceTo =
                        this.priceFilter.priceRange.priceFrom;
                }
            }
        }
        this.priceFilter.isPriceFilterActive = !(
            this.priceFilter.priceRange.priceFrom === undefined &&
            this.priceFilter.priceRange.priceTo === undefined
        );
        this.facetsFilterHelperService.priceFilter.next(this.priceFilter);
    }

    onPrizeToChange(priceTo: string) {
        if (priceTo === "") {
            this.priceFilter.priceRange.priceTo = undefined;
        } else {
            this.priceFilter.priceRange.priceTo = parseInt(priceTo);
            if (this.priceFilter.priceRange.priceFrom !== undefined) {
                if (
                    this.priceFilter.priceRange.priceTo <
                    this.priceFilter.priceRange.priceFrom
                ) {
                    this.priceFilter.priceRange.priceFrom =
                        this.priceFilter.priceRange.priceTo;
                }
            }
        }
        this.priceFilter.isPriceFilterActive = !(
            this.priceFilter.priceRange.priceFrom === undefined &&
            this.priceFilter.priceRange.priceTo === undefined
        );
        this.facetsFilterHelperService.priceFilter.next(this.priceFilter);
    }

    onClearPriceFilter() {
        this.priceFilter.priceRange.priceFrom = undefined;
        this.priceFilter.priceRange.priceTo = undefined;
        this.priceFilter.isPriceFilterActive = false;
        this.facetsFilterHelperService.priceFilter.next(this.priceFilter);
    }
}

export class MapFacetNames {
    name: string;
    displayName: string;
}
