<form [formGroup]="addressForm">
    <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        <div>
            <label
                htmlFor="fullName"
                class="block text-sm font-medium form__title"
            >
                Imię i nazwisko*
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    formControlName="fullName"
                    autoComplete="given-name"
                    class="form__field block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
            </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="company"
                class="block text-sm font-medium form__title"
            >
                Firma
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="company"
                    id="company"
                    formControlName="company"
                    class="form__field  block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="streetLine1"
                class="block text-sm font-medium form__title"
            >
                Ulica*
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="streetLine1"
                    id="streetLine1"
                    formControlName="streetLine1"
                    autoComplete="street-address"
                    class="form__field  block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="streetLine2"
                class="block text-sm font-medium form__title"
            >
                Numer domu / mieszkania*
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="streetLine2"
                    id="streetLine2"
                    formControlName="streetLine2"
                    class="form__field  block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div>
            <label
                htmlFor="city"
                class="block text-sm font-medium form__title"
            >
                Miasto*
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    formControlName="city"
                    class="form__field  block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <!-- TODO -->

<!--        <div>-->
<!--            <label-->
<!--                htmlFor="countryCode"-->
<!--                class="block text-sm font-medium form__title"-->
<!--            >-->
<!--                Kraj-->
<!--            </label>-->
<!--            <div class="mt-1">-->
<!--                <select-->
<!--                    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"-->
<!--                    formControlName="countryCode" id="countryCode">-->
<!--                    <option *ngFor="let country of availableCountries" [ngValue]="country.code">-->
<!--                        {{ country.name }}-->
<!--                    </option>-->
<!--                </select>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div>-->
<!--            <label-->
<!--                htmlFor="province"-->
<!--                class="block text-sm font-medium form__title"-->
<!--            >-->
<!--                Województwo-->
<!--            </label>-->
<!--            <div class="mt-1">-->
<!--                <input-->
<!--                    type="text"-->
<!--                    name="province"-->
<!--                    id="province"-->
<!--                    formControlName="province"-->
<!--                    autoComplete="address-level1"-->
<!--                    class="form__field  block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500-->
<!--            sm:text-sm"-->
<!--                />-->
<!--            </div>-->
<!--        </div>-->

        <div>
            <label
                htmlFor="postalCode"
                class="block text-sm font-medium form__title"
            >
                Kod pocztowy*
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    formControlName="postalCode"
                    autoComplete="postal-code"
                    class="form__field  block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>

        <div class="sm:col-span-2">
            <label
                htmlFor="phoneNumber"
                class="block text-sm font-medium form__title"
            >
                Numer telefonu*
            </label>
            <div class="mt-1">
                <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    formControlName="phoneNumber"
                    autoComplete="tel"
                    class="form__field  block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500
            sm:text-sm"
                />
            </div>
        </div>
    </div>
</form>
