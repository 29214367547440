<div class="border rounded bg-white p-4 text-sm w-2/4 width-100">
	<h6 *ngIf="title"
		class="font-medium address__heading">
		{{ title }}
	</h6>
	<ul class>
		<li class="address__item">
			{{ address.fullName }}
		</li>
        <li class="address__item">
            {{ address.company }}
        </li>
		<li class="address__item">
			{{ address.streetLine1 }} {{address.streetLine2 }}
		</li>
		<li class="address__item">
            {{ address.postalCode }} {{address.city }}
        </li>
        <li class="address__item">
            {{address.phoneNumber }}
        </li>
<!--		<li class="address__item">-->
<!--			{{ getCountryName() }}-->
<!--		</li>-->
	</ul>
</div>
<ng-content></ng-content>
