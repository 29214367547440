<div *ngIf="cart" class="flow-root">
    <div
        *ngFor="let grouped of groupedSeller; index as i"
        class="group-container"
    >

        <!--
        <h1 class="title text border-b border-gray-200">
            Sprzedawca: {{ grouped.name }}
        </h1>
        -->

        <ul role="list">
            <div
                *ngFor="let groupedData of grouped.offersList; trackBy: trackByFn"
            >
            <h1 class="title text border-b border-gray-200">
                Sprzedawca: {{ grouped.name }} (razem produktów: {{ groupedData.line.length }})
            </h1>
                <div *ngFor="let line of groupedData.line; index as j">
                    <li class="py-6 flex w-full">
                        <div class="text-gray-500 space-y-1 text-xs">{{(j+1)}}) &nbsp; </div>
                        <div
                            class="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden"
                        >
                            <img
                                [src]="
                                    line.featuredAsset | assetPreview : 'thumb'
                                "
                                [alt]="line.productVariant.name"
                                class="rounded"
                            />
                        </div>
                        <div class="ml-4 flex-1 flex flex-col">
                            <div>
                                <div
                                    class="flex justify-between text-base font-medium text-gray-900"
                                >
                                    <h3>
                                        <a
                                            [routerLink]="[
                                                '/products',
                                                line.productVariant
                                            ]"
                                        >{{ line.productVariant.name }}</a
                                        >
                                    </h3>
                                    <p class="ml-4">
                                        {{
                                        line.linePriceWithTax | formatPrice
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="flex-1 flex items-center justify-between text-sm text-gray-600"
                            >
                                <div class="flex space-x-4">
                                    <button
                                        *ngIf="canAdjustQuantities"
                                        (click)="decrement(line)"
                                        class="btn btn-sm"
                                    >
                                        <fa-icon
                                            aria-label="Remove 1"
                                            icon="minus"
                                        ></fa-icon>
                                    </button>
                                    <div class="qty">szt.: {{ line.quantity }}</div>
                                    <button
                                        *ngIf="canAdjustQuantities"
                                        (click)="increment(line)"
                                        class="btn btn-sm"
                                    >
                                        <fa-icon
                                            aria-label="Add 1"
                                            icon="plus"
                                        ></fa-icon>
                                    </button>
                                </div>
                                <div class="total">
                                    <div class>
                                        {{
                                        line.unitPriceWithTax | formatPrice
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <div *ngIf="(j+1) === groupedData.line.length">

                        <div class="flex justify-between flex-col items-start gap-1 mb-1 mt-1" *ngIf="showDeliveryTypes">
                            <div *ngIf="activeStage === 1 || activeStage === 2 ">
                                <p class="delivery__text text mb-2">
                                    Wybierz sposób dostawy od: {{ grouped.name }}

                            <!--
                            <select class="border-gray-300 w-full mb-10 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                                <option
                                    *ngFor="
                                    let actualShippingMethod of getActualShippingMethods();
                                "
                                    [value]="actualShippingMethod.seller_shipping_method_id"
                                >
                                    {{ actualShippingMethod.shipping_name }}
                                </option>
                            </select>
                            -->


                                </p>
                                <select (change)="onChangeInput(i, $event.target.value, $event.target.selectedIndex, grouped.code.toLowerCase()) "
                                        class="border-gray-300 w-full mb-10 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                >

                                <ng-container *ngFor="let shippingMethod of findOrderShippingMethods(grouped.code.toLowerCase()); index as sellerShippingMethodIndex, trackBy: trackByFn;">
                                    <!--<ng-container *ngFor="let actualShippingMethod of getActualShippingMethods(i);"> -->
                                        <ng-container *ngIf="shippingMethod.seller_shipping_method_id === getActualShippingMethods(i).seller_shipping_method_id">
                                            <option [selected]="true" [value]="shippingMethod.seller_shipping_method_id"> {{ shippingMethod.shipping_name }} </option>
                                        </ng-container> 
                                        <ng-container *ngIf="shippingMethod.seller_shipping_method_id !== getActualShippingMethods(i).seller_shipping_method_id">
                                            <option [value]="shippingMethod.seller_shipping_method_id"> {{ shippingMethod.shipping_name }} </option>
                                        </ng-container>    
                                    <!-- </ng-container> -->  
                                </ng-container>  
                                
                                    <!-- original 
                                    <option
                                        *ngFor="
                                        let shippingMethod of findOrderShippingMethods(
                                            grouped.code.toLowerCase()
                                        ); index as sellerShippingMethodIndex, trackBy: trackByFn;
                                    "
                                        
                                        [value]="shippingMethod.seller_shipping_method_id"
                                    >
                                        {{ shippingMethod.shipping_name }}
                                    </option>
                                -->
                                </select>
                            </div>
                            <!--
                            <div *ngIf="activeStage === 3">
                                <p class="delivery__text text mb-2">
                                    Wybrany sposób dostawy od: {{ grouped.name }}
                                </p>
                                <select class="border-gray-300 w-full mb-10 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                                    <option [disabled]="true" style=" background-color: #f3f3f3;"
                                        *ngFor="
                                        let shippingMethod of findOrderShippingMethods(
                                            grouped.code.toLowerCase()
                                        ); trackBy: trackByFn;
                                    "
                                        [value]="shippingMethod.seller_shipping_method_id"
                                    >
                                        {{ shippingMethod.shipping_name }}
                                    </option>
                                </select>
                            </div>
                            -->
                           
                        </div>
                    </div>
                </div>

            </div>
        </ul>
    </div>
</div>
