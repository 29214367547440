<div class="cookie__container" *ngIf="!cookie">
  <p class="cookie__text">
    Ta strona wykorzystuje pliki cookie w celu świadczenia usług na najwyższym
    poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich
    użycie. Więcej informacji znajdziesz w naszej Polityce Prywatności.
  </p>
  <button
    (click)="cookiesSubmit()"
    class="bg-primary-700 p-1 text-white rounded hover:bg-sky-600 transition text-xs cookie__button"
  >
    Zaakceptuj
  </button>
</div>
