<dl class="border-t mt-6 border-gray-200 py-6 space-y-6">

    <div class="flex items-center justify-between">
        <dt class="text-sm">Wartość koszyka</dt>
        <dd class="text-sm font-medium text-gray-900">{{ cart.subTotalWithTax | formatPrice }}</dd>
    </div>
    <div class="flex items-center justify-between">
        <dt class="text-sm">
            Koszt wysyłki razem
        </dt>
        <dd class="text-sm font-medium text-gray-900">
            {{ this.shippingCost | formatPrice }}
        </dd>
    </div>
    <div class="flex items-center justify-between border-t border-gray-200 pt-6">
        <dt class="text-base font-medium">Suma</dt>
        <dd class="text-base font-medium text">
            {{ cart.subTotalWithTax + this.shippingCost | formatPrice }}
        </dd>
    </div>
</dl>
