import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'vsf-terms-of-conditions',
  templateUrl: './terms-of-conditions.component.html',
  styleUrls: ['./terms-of-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfConditionsComponent {

}
