<footer class="border-t shadow-md footer 2xl:bottom-0">
  <div class="flex justify-center gap-20 p-4">
    <div>
      <h3 class="text-sm font-medium footer__link--header tracking-wider uppercase">
        <a [routerLink]="['/contact']" class="heading__link">
          Kontakt
        </a>
      </h3>
    </div>

    <div>
      <h3 class="text-sm font-medium footer__link--header tracking-wider uppercase">
        <a [routerLink]="['/terms-of-conditions']" target="_blank" class="heading__link">
          Regulamin
        </a>
      </h3>
    </div>

    <div>
      <h3 class="text-sm font-medium footer__link--header uppercase">
        <a [routerLink]="['/privacy-policy']" target="_blank" class="heading__link">
          Polityka prywatności
        </a>
      </h3>
    </div>
  </div>
</footer>
