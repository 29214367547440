import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
} from "@angular/core";
import {
    CartFragment,
    GetActiveOrderQuery,
} from "../../../common/generated-types";
import { CartContentsService } from "./cart-contents.service";
import { Apollo } from "apollo-angular";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { GET_CODE } from "./cart-contents.component.graphql";
//import { CartTotalsService } from "../cart-totals/cart-totals.service";
//import { CartTotalsComponent } from "../cart-totals/cart-totals.component";
@Component({
    selector: "vsf-cart-contents",
    templateUrl: "./cart-contents.component.html",
    styleUrls: ["./cart-contents.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartContentsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() cart: GetActiveOrderQuery["activeOrder"];
    @Input() canAdjustQuantities = false;
    @Input() groupedSellers: GroupedSeller[] | void | null;
    @Input() showDeliveryTypes: boolean = true;
    @Input() activeStage: number = 0;
    @Output() setQuantity = new EventEmitter<{
        itemId: string;
        quantity: number;
    }>();
    code$: Observable<string>;
    groupedSeller: GroupedSeller[] = [];
    orderShippingNames: any[] = [];
    private querySubscription: Subscription;
    sellers: any[] = [];
    /* 
    actualShipingMethods is an array that holds selected by user shipping methods - one per seller
    indexed with numbers the same as array groupedSeller 
    (e.g. actualShipingMethods[1] will hold selected shipping method for seller defined as groupedSeller[1])
    */
    private actualShipingMethods: any[] = [];

    constructor(
        private cartContentsService: CartContentsService,
        private route: ActivatedRoute,
        private apollo: Apollo,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if(this.showDeliveryTypes) {
            this.querySubscription = this.apollo
                .watchQuery<any>({
                    query: GET_CODE,
                })
                .valueChanges.subscribe(({ data }) => {
                    this.code$ = data.activeOrder.code;
                    this.getOrderShippingMethod();
                });
            this.groupedLines();
        }

    }
    ngAfterViewInit() {
        this.groupedSeller.forEach((seller) => {
            this.findOrderShippingMethodsFirstTime(seller?.code.toLowerCase());
        })
    }

    ngOnDestroy() {
        this.querySubscription?.unsubscribe();
    }
    ngOnChanges(changes: SimpleChanges) {
        if(changes.cart){
            this.groupedLines();
            
                // old: 
                //this.actualShipingMethods = [];
                //this.actualShipingMethods.push({'shipping_name':'test1', 'seller_shipping_method_id':'test2'});
                //this.actualShipingMethods.pop();

                //console.log ('Wartosc actualShipingMethods w if po');
                //console.table (this.actualShipingMethods);
                //console.log (JSON.stringify(this.actualShipingMethods));

            this.groupedSeller.forEach((seller) => {
                this.findOrderShippingMethodsFirstTime(seller?.code.toLowerCase());
            })
            //this.cartTotalsComponent.getOrderShippingCostMethod();
        }
    }

    onChangeInput(selectedSellerIndex: any, selectedSellerShippingMethodIdValue: any, selectedSellerShippingMethodIndex: any, sellerCode: string) {
  
        const sellerShippingMethods = this.findOrderShippingMethods(sellerCode);
        //shippingName = 
        //console.log ('index:' + index);
        //console.log ('sellerShippingMethods');
        //console.log (sellerShippingMethods);
        //console.log ('sellerShippingMethodIndex.selectedIndex:' + sellerShippingMethodIndex.selectedIndex);
        //console.log ('sellerShippingMethodIndex.selectedOptions :' + sellerShippingMethodIndex.selectedOptions );
        //console.log ('sellerShippingMethodIndex.selectedOptions.selectedIndex :' + sellerShippingMethodIndex.selectedIndex );
        
        const previousShippingName =  sellerShippingMethods[selectedSellerShippingMethodIndex]['shipping_name']; 
        //console.log ('previousShippingName: ' + previousShippingName);
        //this.actualShipingMethods[index] = {shipping_name : previousShippingName, seller_shipping_method_id : value};
        this.actualShipingMethods[selectedSellerIndex] = {shipping_name : previousShippingName, seller_shipping_method_id : selectedSellerShippingMethodIdValue};
        this.cartContentsService.setSelectedOptions(this.actualShipingMethods);
      }

    groupedLines() {
        if (this.cart && this.cart.lines) {
            this.groupedSeller = this.groupedByName(this.cart.lines);
        }
    }

    getOrderShippingMethod() {
        //console.log ('Wywolanie getOrderShippingMethod()');
        const body = {
            auth_token_bearer: localStorage.getItem("auth_token") || null,
            data: {
                order_code: this.code$,
            },
        };
        this.cartContentsService
            .getOrderShippingMethods(body)
            .subscribe((response) => {
                this.orderShippingNames = response[0].output_data;
                this.groupedSeller.forEach((seller) => {
                    this.findOrderShippingMethodsFirstTime(seller?.code.toLowerCase());
                })
                this.cdr.detectChanges();
            });
    }

    findOrderShippingMethods(sellerCode: string) {
        //console.log ('Wywolanie findOrderShippingMethods() dla SellerCode = ' + sellerCode);
        //console.log ('this.actualShipingMethods przed');
        //console.log (JSON.stringify(this.actualShipingMethods));
        const shippingMethods = this.orderShippingNames.filter(
            (shippingMethod) => shippingMethod.seller_name === sellerCode
        );
        //console.log (shippingMethods);
        const filteredShippingData: any[] = shippingMethods.map(
            (shippingMethod) => ({
                shipping_name: shippingMethod.shipping_name,
                seller_shipping_method_id:
                    shippingMethod.seller_shipping_method_id,
            })
        );
        //console.log('filteredShippingData');
        //console.log(filteredShippingData);

        //console.log ('this.actualShipingMethods po');
        //console.log (JSON.stringify(this.actualShipingMethods));        

        return filteredShippingData;
    }

    findOrderShippingMethodsFirstTime(sellerCode: string) {
        //console.log ('Wywolanie findOrderShippingMethodsFirstTime() dla sellerCode: ' + sellerCode);
        //console.log ('Sprawdzenie actualShipingMethods na poczatku');
        //console.log (JSON.stringify(this.actualShipingMethods));

        //const sellerArrayIndex : number = 0;
        const shippingMethods = this.orderShippingNames.filter(
            (shippingMethod) => shippingMethod.seller_name === sellerCode
        );
        //console.log ('Wartosc shippingMethods');
        //console.log (shippingMethods);
        const filteredShippingData: any[] = shippingMethods.map(
            (shippingMethod) => ({
                shipping_name: shippingMethod.shipping_name,
                seller_shipping_method_id:
                shippingMethod.seller_shipping_method_id,
            })
        );

        //console.log ('Wartosc filteredShippingData');
        //console.log (JSON.parse(JSON.stringify(filteredShippingData)));
        //console.log ('Wartosc filteredShippingData.length');
        //console.log (JSON.parse(JSON.stringify(filteredShippingData.length)));

        /* old     
        if(filteredShippingData[0]) {
            console.log ('Wartosc actualShipingMethods przed push');
            console.log (JSON.stringify(this.actualShipingMethods));
            this.actualShipingMethods.push(filteredShippingData[0]);
            this.cartContentsService.setSelectedOptions(this.actualShipingMethods);
            console.log ('Wartosc actualShipingMethods po push');
            console.log (JSON.stringify(this.actualShipingMethods));            
        }
        */

        //console.log ('Wartosc groupedSeller po if');
        //console.log ((this.groupedSeller));
        let sellerArrayIndex: number = -1;
        this.groupedSeller.forEach((element, index) => {
            //console.log(element);
            if (element.code === sellerCode ) {
                sellerArrayIndex = index;
                //console.log(sellerArrayIndex);
            } 
        })

        if(filteredShippingData.length > 0 ) { 
            //console.log ('Wartosc actualShipingMethods po if');
            //console.log (JSON.stringify(this.actualShipingMethods));


            //if(this.actualShipingMethods.length === 0) {
            if (sellerArrayIndex !== -1 && typeof this.actualShipingMethods[sellerArrayIndex] === 'undefined') {
                //console.log ('Wartosc actualShipingMethods przed push');
                //console.log (JSON.stringify(this.actualShipingMethods));
                // original 
                // original this.actualShipingMethods.push(filteredShippingData[0]);
                this.actualShipingMethods[sellerArrayIndex] = filteredShippingData[0];
                this.cartContentsService.setSelectedOptions(this.actualShipingMethods);
                //console.log ('Wartosc actualShipingMethods po push');
                //console.log (JSON.stringify(this.actualShipingMethods));            
            }
        }

        return filteredShippingData;
    }

    groupedByName(lines: any[]): GroupedSeller[] {
        //console.log ('Wywolanie groupedByName()');
        const groupedValue: { [key: string]: any[] } = lines.reduce(
            (acc, line) => {
                for (const facetValue of line.productVariant.facetValues) {
                    if (facetValue.facet.code === "seller") {
                        const sellerName = facetValue.name;
                        const code = facetValue.code;
                        this.sellers = [...this.sellers, code];
                        acc[sellerName] = [
                            ...(acc[sellerName] || []),
                            { line, code },
                        ];
                        return acc;
                    }
                }
            },
            {}
        );
        let keyValueSet: any[] = [];
        for (const [key, value] of Object.entries(groupedValue)) {
            const orderShippingValues = {
                name: key,
                code: value[0].code,
                offersList: [{ line: value.map((data) => data.line) }],
            };
            keyValueSet = [...keyValueSet, orderShippingValues];
        }
        return keyValueSet;
    }

    increment(item: CartFragment["lines"][number]) {
        this.setQuantity.emit({ itemId: item.id, quantity: item.quantity + 1 });
    }

    decrement(item: CartFragment["lines"][number]) {
        this.setQuantity.emit({ itemId: item.id, quantity: item.quantity - 1 });
    }

    trackByFn(index: number, line: { id: string }) {
        return line.id;
    }

    trackByDiscount(
        index: number,
        discount: CartFragment["discounts"][number]
    ) {
        return discount.adjustmentSource;
    }

    isDiscounted(line: CartFragment["lines"][number]): boolean {
        return line.discountedLinePriceWithTax < line.linePriceWithTax;
    }

    /**
     * Filters out the Promotion adjustments for an OrderLine and aggregates the discount.
     */
    getLinePromotions(adjustments: CartFragment["discounts"]) {
        const groupedPromotions = adjustments
            .filter((a) => a.type === "PROMOTION")
            .reduce((groups, promotion) => {
                if (!groups[promotion.description]) {
                    groups[promotion.description] = promotion.amount;
                } else {
                    groups[promotion.description] += promotion.amount;
                }
                return groups;
            }, {} as { [description: string]: number });
        return Object.entries(groupedPromotions).map(([key, value]) => ({
            description: key,
            amount: value,
        }));
    }

    getActualShippingMethods(i: number) {
        //console.log ('Wywolanie getActualShippingMethods() dla index: ' + i);
        //console.log (JSON.stringify(this.actualShipingMethods[i]));
          return this.actualShipingMethods[i];
    }

}

interface GroupedSeller {
    name: string;
    code: string;
    offersList: any[];
}
