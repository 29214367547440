import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {Observable, Subscription, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GET_CODE} from "../../../shared/components/cart-contents/cart-contents.component.graphql";
import {Apollo} from "apollo-angular";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'vsf-seller-info',
  templateUrl: './seller-info.component.html',
  styleUrls: ['./seller-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SellerInfoComponent implements OnInit {

    @Input() sellerFriendlyName: string;
    @Output() closeModal = new EventEmitter();
    code$: string;
    htmlContent: SafeHtml;
    private querySubscription: Subscription;



    private getSellerInfoDetails = environment.postgrestApiUrl + "/get_seller_info_details";
    constructor(private http: HttpClient,
                private cdr: ChangeDetectorRef,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.getSellerInfoDetailsMethod();
    }

    closeModalEmitter() {
        this.closeModal.emit(0);
    }

    handleAuthError(error: any): Observable<any> {
        if (error && error.error && error.error.message) {
            console.error(error.error.message);
        } else if (error && error.message) {
            console.error(error.message);
        } else {
            console.error(JSON.stringify(error));
        }
        return throwError(error);
    }

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Prefer": "params=single-object",
        }),
    };

    getSellerInfoDetailsMethod() {
        const body = {
            auth_token_bearer: localStorage.getItem("auth_token") || null,
            data: {
                seller_friendly_name: this.sellerFriendlyName
            },
        };
        return this.http
            .post(this.getSellerInfoDetails, body, this.httpOptions)
            .pipe(catchError((err) => this.handleAuthError(err)))
            .subscribe(res => {
                this.htmlContent = this.sanitizer
                    .bypassSecurityTrustHtml(res[0].output_data[0].seller_info_details);
                this.cdr.detectChanges();
            });
    }

}
