import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormControl } from "@angular/forms";
import { FacetValue } from "../../../common/generated-types";
import { FacetsFilterHelperService } from "../../services/facets-filter-helper.service";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";
import { MapFacetNames } from '../product-list-controls/product-list-controls.component';

@Component({
  selector: 'vsf-single-filter',
  templateUrl: './single-filter.component.html',
  styleUrls: ['./single-filter.component.scss'],
})
export class SingleFilterComponent implements OnInit, OnDestroy{
    @Input() facet: MapFacetNames;
    @Input() initialFacetsValues: FacetValue[];
    @Output() addedFacetValueIdEmitter = new EventEmitter<{facetType: string, id: string}>();
    @Output() deletedFacetValueIdEmitter = new EventEmitter<{facetType: string, id: string}>();
    @Output() clearSelectedFacetAndAddClickedOnLickFacet = new EventEmitter<{facetType: string, id: string}>();
    inputText: FormControl = new FormControl();

    facetsValuesSuggestions: FacetValue[] = [];
    inputChangeSubscription: Subscription;
    selectedFacetValues: FacetValue[] = [];

    constructor(private facetsFilterHelperService: FacetsFilterHelperService)
    {

    }

    ngOnInit(): void {
        this.onFilterValueChange();
        this.onClickFacetListener();
    }

    ngOnDestroy(): void {
        this.inputChangeSubscription.unsubscribe();
    }

    onInputValueChange(value: any) {
        this.facetsFilterHelperService.facetValueChange.next(value);
    }

    onClickFacetListener() {
        this.facetsFilterHelperService.onClickFacetLink
            .subscribe(facetId => {
                this.selectedFacetValues = [];
                let selectedFacet = this.initialFacetsValues?.find(facet => facet.id == facetId);
                if (selectedFacet) {
                    this.selectedFacetValues.push(selectedFacet);
                    this.clearSelectedFacetAndAddClickedOnLickFacet.emit({facetType: this.facet.name, id: selectedFacet.id});
                }
            })
    }

    private onFilterValueChange() {
        this.inputChangeSubscription = this.facetsFilterHelperService.facetValueChange
            .pipe(debounceTime(500))
            .subscribe(value => {
                console.log(value);
                this.facetsValuesSuggestions = this.initialFacetsValues.filter(facetValue => facetValue.name.toLowerCase().includes(value.toLowerCase()));
                this.facetsValuesSuggestions = this.facetsValuesSuggestions.filter(facetValue => !this.selectedFacetValues.some(selected => selected.id === facetValue.id));

            })
    }

    onClickAutoCompleteOption(facetValue: FacetValue) {
        this.selectedFacetValues.push(facetValue);
        this.addedFacetValueIdEmitter.emit({facetType: this.facet.name, id: facetValue.id});
        this.inputText.reset();
        this.getSuggestions();
    }

    onDeleteFacetFromFilter(facetValue: FacetValue) {
        const index = this.selectedFacetValues.indexOf(facetValue);
        if (index > -1) {
            this.selectedFacetValues.splice(index, 1);
            this.deletedFacetValueIdEmitter.emit({facetType: this.facet.name, id: facetValue.id});
        }
        this.facetsValuesSuggestions = this.initialFacetsValues.filter(facet => !this.selectedFacetValues.some(selected => selected.id === facet.id));
    }


    getSuggestions() {
        this.facetsValuesSuggestions = this.initialFacetsValues;
        this.facetsValuesSuggestions = this.facetsValuesSuggestions.filter(facet => !this.selectedFacetValues.some(selected => selected.id === facet.id));
        
        // sort by name
        this.facetsValuesSuggestions.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
    }
}
