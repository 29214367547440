import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, of} from "rxjs";
import {
    CategorySorting, FacetWithSingleValue,
    FacetWithValues,
    ProductWithFacets,
    ProductWithIdAndFacetsResponse, ProductWithVariantsFromResponse, VariantFromResponse
} from "./product-card.component";
import {GET_PRODUCT_WITH_FACETS, GET_VARIANTS} from "../product-detail/product-detail.graphql";
import {map} from "rxjs/operators";
import {DataService} from "../../providers/data/data.service";

@Injectable({
    providedIn: "root",
})
export class ProductService {
    constructor(private dataService: DataService) {
    }

    productVariantsSubject = new BehaviorSubject<CategorySorting[]>([]);
    productVariantIdSubject = new BehaviorSubject<number>(0);
    productDetailsSubject = new BehaviorSubject<ProductWithFacets>({
        artist: [],
        title: [],
        country: [],
        format: [],
        genre: [],
        label: [],
        released: [],
        style: [],
    });

    getProductDetails(productId: string):Observable<FacetWithValues[]> {
        if (productId) {
            return this.dataService.query<ProductWithIdAndFacetsResponse>(GET_PRODUCT_WITH_FACETS, {
                    id: productId
                }
            ).pipe((map((response: ProductWithIdAndFacetsResponse) => {
                    let facetValues = response.product.facetValues;

                    let facetsWithValues: FacetWithValues[] = [];

                    facetValues.forEach(facetValue => {
                        let foundFacetValue = facetsWithValues.find(facet => facet.facetType === facetValue.facet.name);
                        if (foundFacetValue) {
                            foundFacetValue.facetValues.push({id: facetValue.id, facetName: facetValue.name});
                        }
                        else {
                            facetsWithValues.push({facetType: facetValue.facet.name, facetValues: [{id: facetValue.id, facetName: facetValue.name}]})
                        }
                    })

                    return facetsWithValues;
                }
            )))
        }
        let facetsWithValues: FacetWithValues[] = [];
        return of(facetsWithValues);
    }

    initProductWithFacets(facetsWithValues: FacetWithValues[]): ProductWithFacets {
        let productWithFacets: ProductWithFacets = {
            artist: [],
            title: [],
            country: [],
            format: [],
            genre: [],
            label: [],
            released: [],
            style: []
        }
        const formatValues = facetsWithValues.find(facet => facet.facetType === 'Format')?.facetValues;
        if (formatValues) {
            productWithFacets.format.push(...formatValues);
        }

        const title = facetsWithValues.find(facet => facet.facetType === 'Title')?.facetValues;
        if (title) {
            productWithFacets.title.push(...title);
        }

        const released = facetsWithValues.find(facet => facet.facetType === 'Released')?.facetValues;
        if (released) {
            productWithFacets.released.push(...released);
        }

        const genre = facetsWithValues.find(facet => facet.facetType === 'Genre')?.facetValues;
        if (genre) {
            productWithFacets.genre.push(...genre);
        }

        const style = facetsWithValues.find(facet => facet.facetType === 'Style')?.facetValues;
        if (style) {
            productWithFacets.style.push(...style);
        }

        const country = facetsWithValues.find(facet => facet.facetType === 'Country')?.facetValues;
        if (country) {
            productWithFacets.country.push(...country);
        }

        const label = facetsWithValues.find(facet => facet.facetType === 'Label')?.facetValues;
        if (label) {
            productWithFacets.label.push(...label);
        }

        const artist = facetsWithValues.find(facet => facet.facetType === 'Artist')?.facetValues;
        if (artist) {
            productWithFacets.artist.push(...artist);
        }

        return productWithFacets;
    }

    getVariants(productSlag: string): Observable<ProductWithVariantsFromResponse> {
        if (productSlag) {
            return this.dataService
                .query<ProductWithVariantsFromResponse>(
                    GET_VARIANTS,
                    {
                        slug: productSlag,
                    }
                )
        }
        return of(new ProductWithVariantsFromResponse());
    }

    convertVariantFromResponseToDisplayVariant(variantFromResponse: VariantFromResponse): CategorySorting {
        let facetsWithValues: FacetWithSingleValue[] = [];
        let variant: CategorySorting = {
            id: "",
            mediaCondition: "",
            price: undefined,
            priceWithTax: undefined,
            seller: "",
            sleeveCondition: "",
            sellerId: "",
            sleeveConditionId: "",
            mediaConditionId: "",
            stock: undefined,
            deliveryCost: undefined,
            assets: []
        };
        variant.assets = variantFromResponse.assets;
        variantFromResponse.facetValues.forEach(facetValue => {

            facetsWithValues.push({ facetType: facetValue.facet.name, facetValue: { facetName: facetValue.name, id: facetValue.id } });
        })
        const id = variantFromResponse.id;
        if(variantFromResponse.id) {
            variant.id = id;
        }

        const price = variantFromResponse.price;
        if(price) {
            variant.price = price;
        }

        const priceWithTax = variantFromResponse.priceWithTax;
        if(priceWithTax){
            variant.priceWithTax = priceWithTax
        }

        const seller = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Seller')?.facetValue?.facetName;
        if(seller){
            variant.seller = seller;
        }

        const sellerId = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Seller')?.facetValue?.id;
        if(sellerId){
            variant.sellerId = sellerId;
        }

        const mediaCondition = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Media condition')?.facetValue?.facetName;
        if(mediaCondition){
            variant.mediaCondition = mediaCondition;
        }

        const mediaConditionId = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Media condition')?.facetValue?.id;
        if(mediaConditionId){
            variant.mediaConditionId = mediaConditionId;
        }

        const sleeveCondition = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Sleeve condition')?.facetValue?.facetName;
        if(sleeveCondition) {
            variant.sleeveCondition = sleeveCondition;
        }

        const stock = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Stock')?.facetValue?.facetName;
        if(stock) {
            variant.stock = stock;
        }

        const deliveryCost = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Delivery Cost')?.facetValue?.facetName;
        if(deliveryCost) {
            variant.deliveryCost = deliveryCost;
        }

        const sleeveConditionId = facetsWithValues.find(facetWithValue => facetWithValue.facetType === 'Sleeve condition')?.facetValue?.id;
        if(sleeveConditionId) {
            variant.sleeveConditionId = sleeveConditionId;
        }
        return variant;
    }
}
