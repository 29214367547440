<div *ngIf="cart$ | async as cart;" class="mr-4 flex gap-8">
    <button class="cart__button relative"
            (click)="toggle.emit()"
            title="Koszyk">
        <fa-icon icon="shopping-cart" class="mr-1 shopping__cart fa-lg"></fa-icon>
        <span class="absolute -top-2 -right-4 bg-primary-600 rounded-full w-5 h-5 flex items-center justify-center text-xs"
              [class.empty]="cart.quantity === 0"
              [class.cart-changed]="cartChangeIndication$ | async">{{ cart.quantity }}</span>
    </button>
    <vsf-account-link></vsf-account-link>
</div>
