import {Injectable} from "@angular/core";
import {DataService} from "../providers/data/data.service";
import {BehaviorSubject, Subject} from "rxjs";
import {GET_FACET_VALUES} from "../components/product-list/product-list.graphql";
import {PriceFilter, PriceRange} from "../components/product-list-controls/product-list-controls.component";

@Injectable({providedIn: "root"})

export class FacetsFilterHelperService {
    constructor(private dataService: DataService) {
    }

    facetValueChange = new Subject<string>();
    priceFilter = new Subject<PriceFilter>();
    selectedFilters = new BehaviorSubject<{facetType: string, selectedIds: string[]}[]>([]);
    onClickFacetLink = new Subject<string>();

    getFacetsValues(id: string) {
        return this.dataService
            .query(GET_FACET_VALUES, {
                id: id,
            });
    }
}
