<ng-template [ngIf]="product">
	<div class="max-w-8xl mx-6 px-4 text-sm 2xl:mb-20">
		<button class="return__button">
			<a routerLink="/category/vinyls"
				class="return__links">
				<fa-icon icon="chevron-left"
					class="pr-2"></fa-icon>
				Wróć
			</a>
		</button>
		<div class="product__container">
			<div class="text-gray-700 product__container--styled">
				<a [routerLink]="['/product', slug]"
					class="flex"
					prefetch="intent">
					<div [assets]="product?.assets"></div>
					<div [selectedAssetId]="product?.featuredAsset?.id"></div>
					<vsf-asset-gallery [assets]="product?.assets"
						[selectedAssetId]="product?.featuredAsset?.id"
						class="product__image"></vsf-asset-gallery>
				</a>
				<div [innerHTML]="product?.description | safeHtml"
					class="text-base text-gray-700"></div>



				<div class="card__container">
					<div class="font-bold facetElement">
						<span *ngFor="let artist of productWithFacets.artist">
							{{ artist.facetName + "&nbsp;-&nbsp;" }}
						</span>
						<span *ngFor="let title of productWithFacets.title">
							{{ title.facetName }}
						</span>
					</div>



					<div class="facetElement">
						Artysta:
						<div *ngFor="let artist of productWithFacets.artist">
							&nbsp;{{ artist.facetName }}
						</div>
					</div>



					<div class="facetElement">
						Wytwórnia:
						<div *ngFor="let label of productWithFacets.label">
							&nbsp;{{ label.facetName }}
						</div>
					</div>



					<div class="facetElement">
						Format:
						<div *ngFor="let format of productWithFacets.format">
							&nbsp;{{ format.facetName }}
						</div>
					</div>



					<div class="facetElement">
						Kraj wydania:
						<div *ngFor="let country of productWithFacets.country">
							&nbsp;{{ country.facetName }}
						</div>
					</div>



					<div class="facetElement">
						Data wydania:
						<div *ngFor="let released of productWithFacets.released">
							&nbsp;{{ released.facetName }}
						</div>
					</div>



					<div class="facetElement">
						Gatunek:
						<div *ngFor="let genre of productWithFacets.genre">
							&nbsp;{{ genre.facetName }}
						</div>
					</div>



					<div class="facetElement">
						Styl:
						<div *ngFor="let style of productWithFacets.style">
							&nbsp;{{ style.facetName }}
						</div>
					</div>
				</div>
			</div>



			<div *ngIf="product?.variants.length > 1"
				class="price__container">
				<span class="font-normal">
					{{
                        "wszystkie dostępne oferty: " + product.variants.length
                    }}
				</span>
			</div>
		</div>
		<nav class="navigation">
			<ul class="navigation__list"
				routerLinkActive="active">
				<li class="navigation__item">
					<a routerLink="/product"
						class="navigation__link"
						href="#">
						Oferty
					</a>
				</li>
			</ul>
		</nav>



		<div class="table__container"
			role="region"
			tabindex="0">
			<table>
				<thead>
					<tr class="table__row bg-zinc-100 text-zinc-50 shadow">
						<th></th>
						<th>
							Sprzedawca
							<fa-icon icon="caret-down"
								class="table__heading--icon"></fa-icon>
						</th>
						<th>
							Stan płyty
							<fa-icon icon="caret-down"
								class="table__heading--icon"></fa-icon>
						</th>
						<th>
							Stan okładki
							<fa-icon icon="caret-down"
								class="table__heading--icon"></fa-icon>
						</th>
						<th>
							Dostępność
							<fa-icon icon="caret-down"
								class="table__heading--icon"></fa-icon>
						</th>
						<th>
							Cena
							<fa-icon icon="caret-down"
								class="table__heading--icon"></fa-icon>
						</th>
						<th>
							Koszt dostawy
							<fa-icon icon="caret-down"
								class="table__heading--icon"></fa-icon>
						</th>
						<th></th>
						<th></th>
					</tr>
				</thead>


				<tbody>
					<ng-container *ngFor="let variant of variants; index as i">
						<tr *ngIf="selectedVariantId === 0 || selectedVariantId === variant.id">
							<td>
								<ng-container>
									<vsf-product-detail-variant-image [variant]="variant"></vsf-product-detail-variant-image>
								</ng-container>
							</td>
							<td>
								<a class="inline-flex flex flex-col links" href="/seller/{{ variant.seller }}">{{ variant.seller }}</a>&nbsp;<fa-icon icon="circle-info" class="info" (click)="setSellerInfo(variant.seller)"></fa-icon>
							</td>
							<td>
								{{variant.mediaCondition}}
							</td>
							<td>
								{{ variant.sleeveCondition }}
							</td>
							<td>{{ variant.stock }}</td>
							<td>
								{{ variant.priceWithTax | formatPrice }}
							</td>
							<td>
								{{ variant.deliveryCost }}
							</td>
							<td>
								<a *ngIf="product.variants[i].customFields.ProductVariantCustomField1 !== null" (click)="toggleDescriptionView(i)"
									class="links">
									<fa-icon icon="circle-info" class="fa-lg"></fa-icon>
                                    szczegóły
								</a>
							</td>
							<td>
								<button (click)="addToCart(product.variants[i], qty)"
									class="table__button--primary">
									<div>Dodaj do koszyka</div>
								</button>
							</td>
						</tr>
						<tr *ngIf="isShown[i]">
							<td colspan="9" style="white-space: pre-line">
								{{
                                product.variants[i].customFields.ProductVariantCustomField1
                            }}
							</td>
						</tr>
						<!-- <div>
							{{variants | json}}
						</div> -->
					</ng-container>
				</tbody>
			</table>


			<div>
				<div class="offer__links text-primary-700">
					<span *ngIf="variants.length > 1 && selectedVariantId !== 0"
						class="links">
						<a (click)="showMoreOffers()">
							pokaż pozostałe oferty tego produktu...
						</a>
					</span>
				</div>
			</div>
		</div>


		<ng-template #addedToCartTemplate
			let-variant="variant"
			let-quantity="quantity"
			let-close="closeFn">
			<div class="flex">
				<div class="mr-8">
					<img [src]="
                    (variant.featuredAsset?.preview ||
                        product?.featuredAsset?.preview) + '?preset=tiny'
                "
						class="rounded"
						alt="product thumbnail">
				</div>
				<div class="text-sm">
					{{ quantity }} x {{ variant.name }}
				</div>
			</div>
			<div class="flex justify-end">
				<button (click)="viewCartFromNotification(close)"
					type="button"
					class="inline-flex items-center rounded border border-transparent bg-primary-100 px-2.5 py-1.5 text-xs font-medium text-primary-700 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
					Przejdź do koszyka
				</button>
			</div>
		</ng-template>
	</div>
    <vsf-seller-info *ngIf="sellerFriendlyName"
    [sellerFriendlyName]="sellerFriendlyName"
    (closeModal)="setSellerInfo(null)"></vsf-seller-info>
</ng-template>
