<div class="lg:hidden px-2">
    <button
        (click)="manuallyExpanded = !manuallyExpanded"
        class="border border-primary-100 rounded bg-primary-50 p-2 text-sm text-primary-700"
    >
        <fa-icon icon="filter" class="mr-2"></fa-icon>
        Filtry
    </button>
</div>

<div class="my-4 lg:block text-sm space-y-4 fromTo__container">
    <div class="flex items-center gap-2">
        <h2 class="text-sm font-medium block">Cena</h2>

        <input
            type="number"
            class="filter__field rounded p-2 border-gray-200"
            placeholder="od"
            [min]="0"
            [value]="priceFilter.priceRange.priceFrom"
            (input)="onPrizeFromChange($event.target.value)"
        />
        -
        <input
            type="number"
            class="filter__field rounded p-2 border-gray-200"
            placeholder="do"
            [min]="0"
            [value]="priceFilter.priceRange.priceTo"
            (input)="onPrizeToChange($event.target.value)"
        />
    </div>
</div>
<div *ngIf="priceFilter.isPriceFilterActive" class="filter-element-container border border-primary-100 rounded bg-primary-50 p-2 text-primary-700">
    <div *ngIf="priceFilter.priceRange.priceFrom >= 0"> od {{priceFilter.priceRange.priceFrom}}</div>
    <div *ngIf="priceFilter.priceRange.priceTo >= 0"> do {{priceFilter.priceRange.priceTo}}</div>
    <div class="headerDivider"></div>
    <button
        class="close-button"
        aria-label="Close alert"
        type="button"
        data-close
        (click)="onClearPriceFilter()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div>
        <div *ngFor="let filter of filters">
            <vsf-single-filter
                [facet]="filter"
                [initialFacetsValues]="getFacetsValuesFromFacetTypeName(filter.name)"
                (addedFacetValueIdEmitter)="onAddedFacetValueId($event)"
                (deletedFacetValueIdEmitter)="onDeleteFacetValueId($event)"
                (clearSelectedFacetAndAddClickedOnLickFacet)="onClickedFacetLink($event)"
            ></vsf-single-filter>
        </div>
</div>
