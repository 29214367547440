import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'vsf-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesComponent {
  cookie = false;

  ngOnInit() {
    this.getCookieFromLocalStorage();
  }

  getCookieFromLocalStorage() {
    this.cookie = localStorage.getItem('cookie') === 'true';
  }

  cookiesSubmit() {
    this.saveCookieInLocalStorage();
    this.cookie = true;
  }

  saveCookieInLocalStorage() {
    localStorage.setItem('cookie', 'true');
  }
}
