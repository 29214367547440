import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartTotalsService {

  constructor(private http: HttpClient) {}

  private getOrderShippingCostUrl =
      environment.postgrestApiUrl + "/get_order_shipping_cost";

  handleAuthError(error: any): Observable<any> {
      if (error && error.error && error.error.message) {
          console.error(error.error.message);
      } else if (error && error.message) {
          console.error(error.message);
      } else {
          console.error(JSON.stringify(error));
      }
      return throwError(error);
  }

  httpOptions = {
      headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Prefer": "params=single-object",
      }),
  };

  getOrderShippingCost(body: any): Observable<any> {
      return this.http
          .post(this.getOrderShippingCostUrl, body, this.httpOptions)
          .pipe(catchError((err) => this.handleAuthError(err)));
  }
}


