<vsf-layout>
    <vsf-layout-header class="pt-12">
        <div
            class="fixed w-full z-20 top-0 start-0 bg-zinc-600 shadow-lg transform shadow-xl"
        >
            <div
                class="max-w-6xl mx-2 py-2 md:mx-auto flex items-center space-x-4"
            >
                <vsf-mobile-menu-toggle
                    class="md:hidden"
                ></vsf-mobile-menu-toggle>
                <a [routerLink]="['/']" class="l flex items-center gap-2">
                    <img
                        alt="Vinyl logo"
                        class="w-7 logo"
                        src="../assets/logo/logo.svg"
                    />
                    <span class="logo__text">Płytowisko</span>
                </a>
                <vsf-product-search-bar class="flex-1"></vsf-product-search-bar>
                <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>
                <button class="header__button" (click)="showMessage()">
                    Sprzedaj
                </button>
            </div>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile
        [visible]="mobileNavVisible$ | async"
        class="z-10"
    ></vsf-collections-menu-mobile>
    <vsf-cart-drawer
        (close)="closeCartDrawer()"
        [visible]="cartDrawerVisible$ | async"
    ></vsf-cart-drawer>

    <div class="contents">
        <div *ngIf="isVisible" class="popup">
            <div
                class="w-1/4 h-40 shadow-lg rounded contents__message gap-4 p-4"
            >
                <div class="flex items-start justify-between gap-5">
                    <p class="contents__text">
                        Chcesz sprzedać swoje płyty? <br />Zaloguj się i przejdź
                        do sekcji "Moje oferty"
                    </p>
                    <button
                        (click)="closeMessage()"
                        class="contents__button--close"
                    >
                        x
                    </button>
                </div>
                <div class="flex justify-center">
                    <button
                        (click)="closeMessage()"
                        class="contents__link flex justify-center w-1/2 border border-primary-100 rounded bg-primary-50 p-1 text-primary-700"
                    >
                        Zamknij
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isVisible" class="modal-background"></div>

        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-layout-footer></vsf-layout-footer>
    <vsf-cookies></vsf-cookies>
</vsf-layout>
