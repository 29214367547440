<div *ngIf="initialFacetsValues">
    <form>
        <input
            [matAutocomplete]="auto"
            matInput
            [formControl]="inputText"
            class="filter__field rounded p-2 border-gray-200"
            type="text"
            placeholder="{{facet?.displayName}}..."
            aria-label="Search input"
            (click)="getSuggestions()"
            (input)="onInputValueChange($event.target.value)"
        />
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                class="mat__option"
                *ngFor="
                    let facetValue of facetsValuesSuggestions.slice(0, 20)
                "
                [value]="facetValue.name"
                (click)="onClickAutoCompleteOption(facetValue)"
            >
                {{ facetValue.name }}
            </mat-option>
        </mat-autocomplete>

        <div class="flex flex-wrap gap-2">
            <div
                class="filter-element-container border border-primary-100 rounded bg-primary-50 p-2 text-primary-700"
                *ngFor="let selectedfacetValue of selectedFacetValues"
            >
                <div>{{ selectedfacetValue.name }}</div>
                <div class="headerDivider"></div>
                <button
                    class="close-button"
                    aria-label="Close alert"
                    type="button"
                    data-close
                    (click)="onDeleteFacetFromFilter(selectedfacetValue)"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </form>
</div>
