import { Component, Input, OnInit } from "@angular/core";

import {
    FacetValue, PriceRange,
    Scalars,
    SearchProductsQuery,
} from "../../../common/generated-types";
import { DataService } from "../../providers/data/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductService } from "./product-card.service";
import { FacetsFilterHelperService } from "../../services/facets-filter-helper.service";
import { PriceFilter } from "../product-list-controls/product-list-controls.component";

@Component({
    selector: "vsf-product-card",
    templateUrl: "./product-card.component.html",
    styleUrls: ["./product-card.component.scss"],
})

export class ProductCardComponent implements OnInit {
    @Input() product: SearchProductsQuery["search"]["items"][number];
    detailsTableVisible = false;
    cardMeetsPriceRequirement = true;
    variants: CategorySorting[] = [];
    variantsResponse: CategorySorting[]= [];
    selectedFilters: {seller: string[], mediaCondition: string[], sleeveCondition: string[]} = {seller: [], mediaCondition:[], sleeveCondition: []}
    productWithFacets: ProductWithFacets = {
        artist: [],
        title: [],
        country: [],
        format: [],
        genre: [],
        label: [],
        released: [],
        style: []
    };

    sortAsc = 1;

    sortOrder: 'ascending' | 'descending' | undefined;
    iconUp = false;

    priceFilter: PriceFilter = {isPriceFilterActive: false, priceRange: {priceFrom: undefined, priceTo: undefined}};
    sellerFriendlyName: any = null;

    constructor(
        private dataService: DataService,
        private router: Router,
        private route: ActivatedRoute,
        public productService: ProductService,
        private facetsFilterHelperService: FacetsFilterHelperService) {
    }

    ngOnInit(): void {
        this.getProductDetails();
        this.onPriceRangeChange();
        this.onSelectedFiltersChange();
    }

    setSellerInfo(id: any) {
        this.sellerFriendlyName = id;
    }

    toggleArrowIcon() {
        this.iconUp = !this.iconUp;
    }

    sortByCondition(condition: string): void {
        this.sortOrder = this.sortOrder === 'ascending' ? 'descending' : 'ascending';
        this.variants.sort((a, b) => {
            const c: Condition = this.mapEnum(a[condition as keyof CategorySorting]);
            const d: Condition = this.mapEnum(b[condition as keyof CategorySorting]);
            const sortOrderMultiplier = this.sortOrder === 'ascending' ? 1 : -1;
            return sortOrderMultiplier * (c - d);

        });
    }

    mapEnum(conditionString: string): Condition {
        let conditionEnum: Condition = Condition.Mint;
        switch (conditionString) {
            case 'Mint':
                conditionEnum = Condition.Mint;
                break;
            case 'Near-Mint':
                conditionEnum = Condition.NearMint;
                break;
            case 'Very Good Plus':
                conditionEnum = Condition.VeryGoodPlus;
                break;
            case 'Very Good':
                conditionEnum = Condition.VeryGood;
                break;
            case 'Good':
                conditionEnum = Condition.Good;
                break;
            case 'Poor':
                conditionEnum = Condition.Poor;
                break;
            default:
                console.log("Cannot convert string to enum");
                break;
        }
        return conditionEnum;
    }

    sortByPrice(): void {
        this.sortOrder = this.sortOrder === 'ascending' ? 'descending' : 'ascending';

        this.variants.sort((a, b) => {
            const sortOrderMultiplier = this.sortOrder === 'ascending' ? 1 : -1;
            return sortOrderMultiplier * (a.priceWithTax - b.priceWithTax);
        });
    }

    sortBySeller(): void {
        this.sortAsc = -this.sortAsc;
        this.variants = this.variants.sort((a, b) => {
            const sellerA = a.seller;
            const sellerB = b.seller;

            if (sellerA === sellerB) {
                return 0;
            }
            return this.sortAsc * (sellerA < sellerB ? -1 : 1);
        });
    }

    onPriceRangeChange() {
        this.facetsFilterHelperService.priceFilter
            .subscribe((priceFilter: PriceFilter) => {
                this.priceFilter = priceFilter;
                this.cardMeetsPriceRequirement = this.isCardMeetsPriceRequirement();
                this.filterByPrice();
        })
    }

    onSelectedFiltersChange() {
        this.facetsFilterHelperService.selectedFilters
            .subscribe((selectedFilters: {facetType: string, selectedIds: string[]}[]) => {
                this.selectedFilters = {seller: [], mediaCondition:[], sleeveCondition: []};
                selectedFilters.forEach(filter => {
                    if (filter.facetType === 'Seller') {
                        this.selectedFilters.seller.push(...filter.selectedIds);
                    }
                    if (filter.facetType === 'Sleeve condition') {
                        this.selectedFilters.sleeveCondition.push(...filter.selectedIds);
                    }
                    if (filter.facetType === 'Media condition') {
                        this.selectedFilters.mediaCondition.push(...filter.selectedIds);
                    }
                })
                //console.log(this.selectedFilters);
            })
    }

    shouldVariantBeVisibleDependsOnFilters(variant: CategorySorting): boolean {

        let sellerCondition = this.meetSellerCondition(variant);
        let sleeveCondition = this.meetSleeveCondition(variant);
        let mediaCondition = this.meetMediaCondition(variant);

        return sellerCondition && sleeveCondition && mediaCondition;
        }


    meetSellerCondition(variant: CategorySorting): boolean {
        if (this.selectedFilters.seller.length > 0) {
            return this.selectedFilters.seller.some(s => s === variant.sellerId);
        }
        else return true;
    }

    meetSleeveCondition(variant: CategorySorting): boolean {
        if (this.selectedFilters.sleeveCondition.length > 0) {
            return this.selectedFilters.sleeveCondition.some(s => s === variant.sleeveConditionId);
        }
        else return true;
    }

    meetMediaCondition(variant: CategorySorting): boolean {
        if (this.selectedFilters.mediaCondition.length > 0) {
            return this.selectedFilters.mediaCondition.some(s => s === variant.mediaConditionId);
        }
        else {
            return true
        }
    }

    isCardMeetsPriceRequirement(): boolean {
        if (this.priceFilter.isPriceFilterActive) {

            if (this.priceFilter.priceRange.priceFrom != undefined && this.priceFilter.priceRange.priceTo === undefined) {

                return ((this.product?.priceWithTax as PriceRange)?.max /100 > this.priceFilter.priceRange.priceFrom!);
            }

            if (this.priceFilter.priceRange.priceFrom === undefined && this.priceFilter.priceRange.priceTo != undefined) {

                return ((this.product?.priceWithTax as PriceRange)?.min /100 < this.priceFilter.priceRange.priceTo!);
            }

            if (this.priceFilter.priceRange.priceFrom !=undefined && this.priceFilter.priceRange.priceTo !=undefined) {

                return (((this.product?.priceWithTax as PriceRange)?.min /100 > this.priceFilter.priceRange.priceFrom!)
                    && ((this.product?.priceWithTax as PriceRange)?.min /100 < this.priceFilter.priceRange.priceTo!))
                || (((this.product?.priceWithTax as PriceRange)?.max /100 > this.priceFilter.priceRange.priceFrom!)
                        && ((this.product?.priceWithTax as PriceRange)?.max /100 < this.priceFilter.priceRange.priceTo!))
            }
            return true;
        }
        else {
            return true;
        }

    }

    filterByPrice() {
        if( this.priceFilter.isPriceFilterActive) {
            if (this.priceFilter.priceRange.priceFrom != undefined && this.priceFilter.priceRange.priceTo === undefined) {
                this.variants =
                    this.variantsResponse.filter(variant => variant.priceWithTax / 100 > this.priceFilter.priceRange.priceFrom!)
            }
            if (this.priceFilter.priceRange.priceFrom === undefined && this.priceFilter.priceRange.priceTo != undefined) {
                this.variants =
                    this.variantsResponse.filter(variant => variant.priceWithTax / 100 < this.priceFilter.priceRange.priceTo!)
            }
            if (this.priceFilter.priceRange.priceFrom !=undefined && this.priceFilter.priceRange.priceTo !=undefined) {
                this.variants =
                    this.variantsResponse.filter(variant => ( variant.priceWithTax /100 > this.priceFilter.priceRange.priceFrom! )
                        && (variant.priceWithTax /100 < this.priceFilter.priceRange.priceTo!));
            }
        }
        else {
            this.variants = this.variantsResponse;
        }
    }

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    onClickShowDetails(variantId: number) {
        this.productService.productVariantIdSubject.next(variantId);
    }

    onClickShowOffers() {
        if (this.variants?.length !== 0) {
            this.filterByPrice();
            this.detailsTableVisible = !this.detailsTableVisible;
            return;
        }
        this.productService.getVariants(this.product?.slug)
            .subscribe((response) => {
                if (response) {
                    response.product?.variants.forEach(variant => {
                        const singleVariant = this.productService.convertVariantFromResponseToDisplayVariant(variant);
                        this.variants.push(singleVariant);
                    });
                    this.variantsResponse = Object.assign([], this.variants);
                    this.detailsTableVisible = !this.detailsTableVisible;
                    this.filterByPrice();
                }
            });
    }

    getProductDetails() {
        this.productService.getProductDetails(this.product?.productId)
            .subscribe((facetsWithValues) => {
                this.productWithFacets = this.productService.initProductWithFacets(facetsWithValues);
            });

    }

    changeRoute(id: string): void {
        this.facetsFilterHelperService.onClickFacetLink.next(id);
        this.router.navigate(['./', {
            facets: id
        }], {
            queryParamsHandling: 'merge',
            relativeTo: this.route,
            state: {
                noScroll: true,
            },
        });
    }
}

export enum Condition {
    Mint,
    NearMint,
    VeryGoodPlus,
    VeryGood,
    Good,
    Poor,
}

export class ProductWithFacets {
    format: FacetValueWithId[];
    released: FacetValueWithId[];
    genre: FacetValueWithId[];
    style: FacetValueWithId[];
    country: FacetValueWithId[];
    label: FacetValueWithId[];
    artist: FacetValueWithId[];
    title: FacetValueWithId[];
}

export class FacetWithValues {
    facetType: string;
    facetValues: FacetValueWithId[];
}

export class FacetWithSingleValue {
    facetType: string;
    facetValue: FacetValueWithId;
}

export class FacetValueWithId {
    id: string;
    facetName: string;
}

export class ProductWithIdAndFacets {
    id: number;
    facetValues: FacetValue[];
}

export class ProductWithIdAndFacetsResponse {
    product: ProductWithIdAndFacets;
}

export class ProductWithVariantsFromResponse {
    product: ProductWithVariants;
}

export class ProductWithVariants {
    id: string;
    variants: VariantFromResponse[];
}

export class CategorySorting {
    id: string;
    seller: string;
    mediaCondition: string;
    sleeveCondition: string;
    price: any;
    priceWithTax: any;
    sellerId: string;
    mediaConditionId: string;
    sleeveConditionId: string;
    stock: any;
    deliveryCost: any;
    assets: Array<{
        preview: string;
    }>;
}

export class VariantFromResponse {
    id: string;
    price: Scalars['Money'];
    priceWithTax: Scalars['Money'];
    facetValues: FacetValue[];
    assets: Array<{
        preview: string;
    }>;
}
