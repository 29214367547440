import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'vsf-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent {

}
