<ng-template [ngIf]="product">
    <div *ngIf="cardMeetsPriceRequirement">
    <div class="product-card-container">
        <div class="product-card-image-and-description-container">
            <div class="flex">
                <a prefetch='intent' [routerLink]="['/product', product.slug]">
                <img
                    [src]="product.productAsset | assetPreview : 200 : 200"
                    class="flex-grow object-cover aspect-[7/8] cursor-pointer"
                    alt="vinyl cover photo"
                    [routerLink]="['/product', product.slug]"
                />
                </a>
            </div>

            <div
                *ngIf="productWithFacets"
                class="text-sm product-card-description-container"
            >
                <div
                    class="font-medium facetElement details__hover cursor-pointer text-primary-700"
                    [routerLink]="['/product', product.slug]"
                >
                <a class="flex flex-col font-bold" prefetch='intent' [routerLink]="['/product', product.slug]">
                    {{ product.productName }}</a>
                </div>

                <div class="facetElement">
                    Artysta:
                    <div *ngFor="let artist of productWithFacets.artist">
                        <button
                            class="details__hover text-primary-700"
                            (click)="changeRoute(artist.id)"
                        >
                            &nbsp;{{ artist.facetName }}
                        </button>
                    </div>
                </div>

                <div class="facetElement">
                    Wytwórnia:
                    <div *ngFor="let label of productWithFacets.label">
                        <button
                            class="details__hover text-primary-700"
                            (click)="changeRoute(label.id)"
                        >
                            &nbsp;{{ label.facetName }}
                        </button>
                    </div>
                </div>
                <div class="facetElement">
                    Format:
                    <div *ngFor="let format of productWithFacets.format">
                        <button
                            class="details__hover text-primary-700"
                            (click)="changeRoute(format.id)"
                        >
                            &nbsp;{{ format.facetName }}
                        </button>
                    </div>
                </div>
                <div class="facetElement">
                    Kraj wydania:
                    <div *ngFor="let country of productWithFacets.country">
                        <button
                            class="details__hover text-primary-700"
                            (click)="changeRoute(country.id)"
                        >
                            &nbsp;{{ country.facetName }}
                        </button>
                    </div>
                </div>
                <div class="facetElement">
                    Data wydania:
                    <div *ngFor="let released of productWithFacets.released">
                        <button
                            class="details__hover text-primary-700"
                            (click)="changeRoute(released.id)"
                        >
                            &nbsp;{{ released.facetName }}
                        </button>
                    </div>
                </div>
                <div class="facetElement">
                    Gatunek:
                    <div *ngFor="let genre of productWithFacets.genre">
                        <button
                            class="details__hover text-primary-700"
                            (click)="changeRoute(genre.id)"
                        >
                            &nbsp;{{ genre.facetName }}
                        </button>
                    </div>
                </div>
                <div class="facetElement">
                    Styl:
                    <div *ngFor="let style of productWithFacets.style">
                        <button
                            class="details__hover text-primary-700"
                            (click)="changeRoute(style.id)"
                        >
                            &nbsp;{{ style.facetName }}
                        </button>
                    </div>
                </div>
                <div>
                    <button
                        (click)="onClickShowOffers(); toggleArrowIcon()"
                        class="link"
                    >
                        pokaż oferty
                        <fa-icon
                            [icon]="iconUp ? 'chevron-up' : 'chevron-down'"
                            class="show__offers--icon"
                        ></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="price_with_offers-container">
            <div class="text-sm font-medium font-bold price__container">
                <ng-template
                    [ngIf]="
                        product.priceWithTax.min === product.priceWithTax.max
                    "
                    [ngIfElse]="range"
                >
                    {{ product.priceWithTax.min | formatPrice }}
                </ng-template>
                <ng-template #range>
                    {{ product.priceWithTax.min | formatPrice }} -
                    {{ product.priceWithTax.max | formatPrice }}
                </ng-template>
            </div>
            <div class="text-sm availableOffers__container" *ngIf="iconUp">
                {{ "dostępne oferty: " + variants.length }}
            </div>
        </div>
    </div>

    <table *ngIf="detailsTableVisible" class="table">
        <tbody class="table__body">
            <ng-container>
                <tr class="table__row shadow">
                    <th class="table__heading" (click)="sortBySeller()">
                        Sprzedawca
                        <fa-icon
                            icon="caret-down"
                            class="table__heading--icon"
                        ></fa-icon>
                        <!-- <input class="table__field" /> -->
                    </th>
                    <th
                        class="table__heading"
                        (click)="sortByCondition('mediaCondition')"
                    >
                        Stan płyty
                        <fa-icon
                            icon="caret-down"
                            class="table__heading--icon"
                        ></fa-icon>
                        <!-- <input class="table__field" /> -->
                    </th>
                    <th
                        class="table__heading"
                        (click)="sortByCondition('sleeveCondition')"
                    >
                        Stan okładki
                        <fa-icon
                            icon="caret-down"
                            class="table__heading--icon"
                        ></fa-icon>
                        <!-- <input class="table__field" /> -->
                    </th>
                    <th class="table__heading">
                        Dostępność
                        <fa-icon
                            icon="caret-down"
                            class="table__heading--icon"
                        ></fa-icon>
                        <!-- <input class="table__field" /> -->
                    </th>
                    <th class="table__heading" (click)="sortByPrice()">
                        Cena
                        <fa-icon
                            icon="caret-down"
                            class="table__heading--icon"
                        ></fa-icon>
                        <!-- <input class="table__field" /> -->
                    </th>
                    <th class="table__heading">
                        Koszt dostawy
                        <fa-icon
                            icon="caret-down"
                            class="table__heading--icon"
                        ></fa-icon>
                        <!-- <input class="table__field" /> -->
                    </th>
                    <th class="table__heading table__heading--disabled"></th>
                </tr>
                <ng-container *ngFor="let variant of variants">
                <tr *ngIf="shouldVariantBeVisibleDependsOnFilters(variant)">

					<th class="table__header">
                        <a class="inline-flex flex flex-col link" href="/seller/{{ variant.seller }}">{{ variant.seller }}</a>&nbsp;<fa-icon icon="circle-info" class="info" (click)="setSellerInfo(variant.seller)"></fa-icon>
                    </th>                    
                    <th class="table__header">{{ variant.mediaCondition }}</th>
                    <th class="table__header">{{ variant.sleeveCondition }}</th>
                    <th class="table__header">{{ variant.stock }}</th>
                    <th class="table__header">
                        {{ variant.priceWithTax | formatPrice }}
                    </th>
                    <th class="table__header">{{variant.deliveryCost}}</th>
                    <th class="table__header table__link">
                        <a
                            [routerLink]="['/product', product.slug]"
                            class="link"
                            prefetch="intent"
                            (click)="onClickShowDetails(variant.id)"
                        >
                            pokaż szczegóły
                        </a>
                    </th>
                </tr>
            </ng-container>
            </ng-container>
            </tbody>
        </table>
        <hr class="horizontalDivider"/>
    </div>
    <vsf-seller-info *ngIf="sellerFriendlyName"
        [sellerFriendlyName]="sellerFriendlyName"
        (closeModal)="setSellerInfo(null)"></vsf-seller-info>
</ng-template>

