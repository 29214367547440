import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input, ChangeDetectorRef,
    Output, SimpleChanges,
} from "@angular/core";
import { CartFragment } from "../../../common/generated-types";
import { Observable, of, Subscription } from "rxjs";
import { CartTotalsService } from "./cart-totals.service";
import { Apollo } from "apollo-angular";
import { GET_CODE } from "../cart-contents/cart-contents.component.graphql";
import { CartContentsService } from "../cart-contents/cart-contents.service";
import { debounceTime, map } from "rxjs/operators";

@Component({
    selector: "vsf-cart-totals",
    templateUrl: "./cart-totals.component.html",
    styleUrls: ["./cart-totals.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartTotalsComponent implements OnInit {
    @Input() cart: CartFragment;
    @Input() activeStage: number = 0;
    code$: Observable<string>;
    private querySubscription: Subscription;
    private selectContentSubscription: Subscription;
    private selectedOptions: any[] = [];
    shippingCost: number = 0;
    

    constructor(
        private cartTotalsService: CartTotalsService,
        private apollo: Apollo,
        private cdr: ChangeDetectorRef,
        public cartContentsService: CartContentsService,
    ) {}

    ngOnInit(): void {
        //console.log ('Wywolanie ngOnInit on totals');
        this.querySubscription = this.apollo
            .watchQuery<any>({
                query: GET_CODE,
            })
            .valueChanges.subscribe(({ data }) => {
                this.code$ = data.activeOrder.code;
            });
        //console.log ('this.selectContentSubscription:');
        //console.log (this.selectContentSubscription);
        this.selectContentSubscription = this.cartContentsService.selectedOptions$
            .pipe(debounceTime(200),
                map(selectedOptions => {
                this.selectedOptions = [];
                //console.log('Inside pipe1');
                //console.log(this.selectedOptions);
                selectedOptions?.forEach((opt => {
                    this.selectedOptions.push({seller_shipping_method_id: opt.seller_shipping_method_id })
                }));
                //console.log('Inside pipe2');
                //console.log(this.selectedOptions);
                return of(this.selectedOptions);
            }))
            .subscribe((selectedOptions) => {
                //console.log ('Inside subscribe');
                //console.log (this.selectedOptions);
                //console.log ('Active Stage' + this.activeStage);
     
                //console.log (this.selectedOptions);
                //console.log ('Length ' + this.selectedOptions.length)
                if(this.selectedOptions.length && (this.activeStage === 1 || this.activeStage === 2)) {
                    this.getOrderShippingCostMethod();
                }
        })
    }

    
    ngOnChanges(changes: SimpleChanges) {
        //console.log ('Wywolanie ngOnChanges on totals');
        if(changes.cart){
            //console.log(JSON.stringify(changes.cart));
            //console.table(this.cart);
            if(this.activeStage === 1 || this.activeStage === 2) {
                this.getOrderShippingCostMethod();
            }
            if(this.activeStage === 3) {
                this.shippingCost = (this.cart.shippingWithTax);
            }            

            
        }
    } 
       

    ngOnDestroy() {
        this.querySubscription.unsubscribe();
        this.selectContentSubscription.unsubscribe();
    }

    getOrderShippingCostMethod() {
        if(!this.code$) return;
        if(this.cart.totalQuantity === 0) return;

        //console.log ('Wywolujemy getOrderShippingCostMethod');
        //console.log (this.selectedOptions);
        //console.log ('Active Stage' + this.activeStage);

        //console.log('Wartosc this.shippingCost przed');
        //console.log (JSON.stringify(this.shippingCost)); 

        const body = {
            auth_token_bearer: localStorage.getItem("auth_token") || null,
            data: {
                order_code: this.code$,
                seller_shipping_method: this.selectedOptions
            },
        };
        this.cartTotalsService
            .getOrderShippingCost(body)
            .subscribe((response) => {
                this.cartContentsService.setPaymentMethodId(response[0].output_data[0].order_shipping_method_id);


                //console.log('Wartosc shipping_cost zwrocona przez API');
                //console.log (JSON.stringify(response[0].output_data[0].order_total_shipping_cost * 100));

                this.shippingCost = response[0].output_data[0].order_total_shipping_cost * 100;
                this.cdr.detectChanges();
            });
            //console.log('Wartosc this.shippingCost po');
            //console.log (JSON.stringify(this.shippingCost));  
    }
}
