<div class="flex items-center gap-2" [ngClass]="{ darkOverlay: isImageZoomed }">
    <div class="flex items-center gap-2">
        <div>
            <fa-icon
                icon="chevron-left"
                *ngIf="
                    currentIndex !== variant.assets.length - 1 &&
                    variant.assets.length > 0
                "
                (click)="showNextImage()"
                class="cursor-pointer icon"
            ></fa-icon>
        </div>
        <img
            *ngIf="variant.assets.length > 0"
            (click)="switchImageZoom()"
            class="thumbnail__image cursor-zoom-in"
            [src]="variant.assets[currentIndex]?.preview"
            alt="product thumbnail"
        />
        <div>
            <fa-icon
                icon="chevron-right"
                *ngIf="currentIndex !== 0 && variant.assets.length > 0"
                (click)="showPreviousImage()"
                class="cursor-pointer icon"
            ></fa-icon>
        </div>
        <img
            *ngIf="isImageZoomed"
            (click)="switchImageZoom()"
            [ngClass]="{ zoomEffect: isImageZoomed }"
            class="thumbnail__image--zoom cursor-zoom-in"
            [src]="variant.assets[currentIndex]?.preview"
            alt="product thumbnail"
        />
    </div>
</div>
