<div class="max-w-8xl mx-auto px-4 main__container pt-2">
	<ng-container *ngIf="collection$ | async as collection">
		<!-- <div class="flex justify-between items-center">
			<h2 class="text-3xl sm:text-3xl ml-3 font-light tracking-tight text-gray-900 my-8">{{collection.name}}</h2>
		</div> -->
		<vsf-collection-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"></vsf-collection-breadcrumbs>

		<ng-container *ngIf="collection.children.length">
			<div class="max-w-2xl mx-auto py-16 sm:py-16 lg:max-w-none border-b mb-16">
				<h2 class="text-2xl font-light text-gray-900">Kolekcje</h2>
				<div class="mt-6 grid max-w-xs sm:max-w-none mx-auto sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-4">
					<vsf-collection-card *ngFor="let child of collection.children"
						[collection]="child">
					</vsf-collection-card>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<h2 *ngIf="searchTerm$ | async as term"
		class="text-3xl sm:text-3xl ml-3 font-light tracking-tight text-gray-900 my-8">
		Wyniki dla
		<span class="font-medium">"{{ term }}"</span>
	</h2>

	<h2 *ngIf="searchResultError" class="ml-3 font-medium">{{searchResultError}}</h2>



	<div class="product-list-controls-and-cards-container">
		<div class="product-list-controls-container">
			<vsf-product-list-controls
                [facetValues]="facetValues"
				[activeFacetValueIds]="activeFacetValueIds$ | async"
				[totalResults]="unfilteredTotalItems"
                (selectedFiltersEventEmitter)="onChangeFilters($event)"
				class="mb-4"></vsf-product-list-controls>
		</div>

		<div class="product-cards-container">
			<div class="items-center flex justify-center h-full" *ngIf="searchResultError">
			<h2 class="ml-3 font-medium">{{searchResultError}}</h2>
		</div>
			<div>
				<ng-container *ngIf="(totalResults$ | async) !== null; else placeholders">
					<vsf-product-card *ngFor="let product of products$ | async; trackBy: trackByProductId"
						[product]="product"></vsf-product-card>
				</ng-container>
				<ng-template #placeholders>
					<vsf-product-card *ngFor="let product of placeholderProducts"
						[product]="product"></vsf-product-card>
				</ng-template>
			</div>
			<div *ngIf="displayLoadMore$ | async"
				class="load-more flex-fill">
				<button [disabled]="loading$ | async"
					(click)="loadMore()"
					class="btn btn-light btn-lg d-inline-flex align-items-center text-primary-700">
					Pokaż kolejne oferty
					<span [class.show]="loading$ | async"
						class="loading-indicator spinner-grow spinner-grow-sm"
						role="status"
						aria-hidden="true"></span>
				</button>
			</div>
		</div>
	</div>

	<ng-template #noResults>
		<div class="no-results col-12">
			<p class="h1">Brak wyników</p>
			<fa-icon icon="sad-cry"
				size="10x"></fa-icon>
		</div>
	</ng-template>
</div>
