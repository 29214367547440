import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CategorySorting } from '../product-card/product-card.component';

@Component({
  selector: 'vsf-product-detail-variant-image',
  templateUrl: './product-detail-variant-image.component.html',
  styleUrls: ['./product-detail-variant-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDetailVariantImageComponent {
  @Input() variant: CategorySorting;
  isImageZoomed = false;
  currentIndex = 0;

  switchImageZoom() {
    this.isImageZoomed = !this.isImageZoomed;
  }
  
  showNextImage() {
    this.currentIndex += 1;
  }

  showPreviousImage() {
    this.currentIndex -= 1;
  }
}
